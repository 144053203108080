<template lang="html">
  <GradientBackLayout
    class="RedeemPageV2"
    :title="$t('redeem_header')"
    :shadowText="$t('redeem_header')"
  >
    <BoxShadow class="RedeemPageV2--Box">
      <div class="RedeemPageV2__Tabs" v-if="isClient">
        <div
          class="RedeemPageV2__Tabs--tab"
          :class="{ active: activeTab == 0 }"
          v-on:click="activeTab = 0"
        >
          {{ $t('redeem_info') }}
        </div>
        <div
          class="RedeemPageV2__Tabs--tab"
          :class="{ active: activeTab == 1 }"
          v-on:click="activeTab = 1"
        >
          {{ $t('redeem_management') }}
        </div>
        <div
          class="RedeemPageV2__Tabs--tab"
          :class="{ active: activeTab == 2 }"
          v-on:click="activeTab = 2"
        >
          {{ $t('redeem_completed') }}
        </div>
        <div
          class="RedeemPageV2__Tabs--tab"
          v-if="communityHasRaffle"
          :class="{ active: activeTab == 3 }"
          v-on:click="activeTab = 3"
        >
          {{ $t('redeem_raffle') }}
        </div>
      </div>
      <div v-show="activeTab == 0">
        <h1>{{ $t('redeem_date_text1') }} {{ $t('redeem_date_text2') }}</h1>
        <div class="RedeemPageV2--Dates">
          <div class="RedeemPageV2--Date">
            <p>{{ $t('redeem_enabled_date_text') }}</p>
            <CommunityInput
              :disabled="true"
              ref="dateFrom"
              :required="true"
              v-model="redeemEdition.pointsRedeemDate"
              :lang="theme.language ? theme.language : 'es'"
              type="datetime"
              format="dd-MM-yyyy HH:mm"
            />
          </div>
          <div class="RedeemPageV2--Date">
            <p>{{ $t('redeem_to_day_text') }}</p>
            <CommunityInput
              :disabled="true"
              ref="dateTo"
              :required="true"
              v-model="redeemEdition.pointsRedeemDateTo"
              :lang="theme.language ? theme.language : 'es'"
              type="datetime"
              format="dd-MM-yyyy HH:mm"
            />
          </div>
        </div>
        <p class="RedeemPageV2--InfoText">
          {{ $t('redeem_user_info_text1') }}{{ $t('redeem_user_info_text2') }}
        </p>
        <Separator />
        <div class="RedeemPageV2--Cards">
          <div
            class="RedeemPageV2--Card"
            v-for="c in theme.redeemCards"
          >
            <div class="RedeemPageV2--CardHeader">
              <div
                :style="{ 'background-image': 'url(\'' + c.logo + '\')' }"
              ></div>
              <CommunityButton
                v-on:click="openModal(c)"
                :disabled="!redeemEnabled"
                type="button"
                name="button"
                >{{ $t('action_redeem_prize') }}</CommunityButton
              >
            </div>
            <p>{{ c.description }}</p>
          </div>
        </div>
      </div>
      <div v-show="activeTab == 1" class="RedeemPageV2--Management">
        <CommunityButton
          v-if="isCommunityMainAdmin"
          style="margin-bottom: 19px"
          v-on:click="fetchExcel()"
          >{{ $t('download_excel') }}</CommunityButton
        >
        <table>
          <tr>
            <th>
              <div class="Tablecheckbox" v-on:click="selectAllRedeem()">
                <input type="checkbox" v-model="allRedeemCheck" />
                <label :for="'checkbox'"></label>
              </div>
            </th>
            <th>{{ $t('role_user') }}</th>
            <th>{{ $t('redeem_redeem_type') }}</th>
            <th>{{ $t('labs_redeemed') }}</th>
            <th></th>
          </tr>
          <div class="spinner__management" v-if="!isRedeemManagementLoaded">
            <i class="fas fa-spinner"></i>
          </div>
          <tr v-for="r in pendingRedeems.objects" v-if="isRedeemManagementLoaded">
            <td>
              <div class="Tablecheckbox" v-on:click="allRedeemCheck = false">
                <input
                  type="checkbox"
                  :id="'checkbox' + r.identifier"
                  v-model="r.selected"
                />
                <label :for="'checkbox' + r.identifier"></label>
              </div>
            </td>
            <td>
              <CommunityUser
                v-if="r.member"
                :member="r.member"
                :allowRemind="false"
                :hideGamification="theme.hideGamification"
              />
            </td>
            <td class="center">
              <p v-if="r.redeemType == 'CUSTOM'">{{ r.sortName }}</p>
              <p v-if="r.redeemType != 'CUSTOM'">
                {{ $filters.formatRedeemType(r.redeemType) }}
              </p>
            </td>
            <td class="center">
              <p>{{ r.points }}</p>
            </td>
            <td class="center">
              <CommunityButton
                v-on:click="
                  redeemUser = r;
                  hideCompletionModal = false
                "
                >{{ $t('action_complete') }}</CommunityButton
              >
            </td>
          </tr>
        </table>
        <Pagination
          v-if="pendingRedeems.pages > 1"
          v-model="pagination"
          :records="pendingRedeems.count"
          :per-page="pendingRedeems.pageSize"
          @paginate="fetchPendingRedeems"
        />

        <CommunityButton
          style="margin-top: 19px"
          v-on:click="
            redeemUser = false;
            hideCompletionModal = false
          "
          >{{ $t('redeem_complete_selected') }}</CommunityButton
        >
      </div>
      <div v-show="activeTab == 2" class="RedeemPageV2--Management">
        <table>
          <tr>
            <th>{{ $t('role_user') }}</th>
            <th>{{ $t('redeem_redeem_type') }}</th>
            <th>{{ $t('labs_redeemed') }}</th>
            <th>{{ $t('comments') }}</th>
          </tr>
          <tr v-for="r in completedRedeems.objects">
            <td>
              <CommunityUser
                v-if="r.member"
                :member="r.member"
                :allowRemind="false"
                :hideGamification="theme.hideGamification"
              />
              <div class="TesterNotExists" v-if="!r.member">
                <p>{{ $t('tester_not_exists') }}</p>
              </div>
            </td>
            <td class="center">
              <p v-if="r.redeemType == 'CUSTOM'">{{ r.sortName }}</p>
              <p v-if="r.redeemType != 'CUSTOM'">
                {{ $filters.formatRedeemType(r.redeemType) }}
              </p>
            </td>
            <td class="center">
              <p>{{ r.points }}</p>
            </td>
            <td class="center">{{ r.comments }}</td>
          </tr>
        </table>
        <Pagination
          v-if="completedRedeems.pages > 1"
          v-model="pagination"
          :records="completedRedeems.count"
          :per-page="completedRedeems.pageSize"
          @paginate="fetchCompleteRedeems"
        />
      </div>
      <div v-show="activeTab == 3">
        <table>
          <tr>
            <th class="center">{{ $t('activity') }} ID</th>
            <th class="center">{{ $t('activity') }}</th>
            <th class="center">{{ $t('redeem_raffle_image') }}</th>
            <th class="center">{{ $t('form_description') }}</th>
            <th class="center">{{ $t('redeem_raffle_finished') }}</th>
            <th class="center">{{ $t('redeem_raffle_redeemed') }}</th>
            <th class="center">{{ $t('redeem_raffle_winners') }}</th>
            <th class="center">{{ $t('redeem_raffle_notification') }}</th>
            <th></th>
          </tr>
          <div class="spinner__raffle" v-if="!isRedeemRaffleLoaded">
            <i class="fas fa-spinner"></i>
          </div>
          <tr v-for="r in raffleList" v-if="isRedeemRaffleLoaded">
            <td class="center">
              <p>{{ r.activityId }}</p>
            </td>
            <td class="center">
              <p>{{ r.activityTitle }}</p>
            </td>
            <td class="center">
              <img style="max-width: 100px;height: auto;" :src="r.prizeImage " alt="">
            </td>
            <td class="center">
              <p>{{ r.prizeDescription }}</p>
            </td>
            <td class="center">
              <i v-if="!r.raffleFinished" style="color:red; font-size: 25px;" class="fas fa-times"></i>
              <i v-if="r.raffleFinished" style="color:green; font-size: 25px;" class="fas fa-check"></i>
            </td>
            <td class="center">
              <i v-if="!r.raffleRedeemed" style="color:red; font-size: 25px;" class="fas fa-times"></i>
              <i v-if="r.raffleRedeemed" style="color:green; font-size: 25px;" class="fas fa-check"></i>
            </td>
            <td class="center">
              <p v-html="getWinnersList(r.winnerList)"></p>
            </td>
            <td class="center">
              <i v-if="!r.winnersNotified" style="color:red; font-size: 25px;" class="fas fa-times"></i>
              <i v-if="r.winnersNotified" style="color:green; font-size: 25px;" class="fas fa-check"></i>
            </td>
            <td class="center">
              <CommunityButton v-on:click="notificateRaffle(r)" v-if="!r.winnersNotified">{{ $t('action_ask_notification') }}</CommunityButton>
            </td>
            <td class="center">
              <CommunityButton v-on:click="redeemRaffle(r)" v-if="r.raffleFinished && !r.raffleRedeemed">{{ $t('action_complete') }}</CommunityButton>
            </td>
          </tr>
        </table>
      </div>
    </BoxShadow>
    <div class="Modal" v-if="!hideModal">
      <div class="Modal__content">
        <div class="Modal__header">
          <span class="close" v-on:click="closeModal()">&times;</span>
          <h2>{{ $t('redeem') }} LABs</h2>
        </div>
        <div class="Modal__body">
          <div class="Modal__body__content">
            <div class="imgs">
              <img :src="currentRedeemPoints.logo" />
            </div>
            <div>
              <p>{{ currentRedeemPoints.description }}</p>
              <p>
                <b>LABs</b
                >{{ $t('labs_user_amount', { points: userProfile.points }) }}
              </p>
              <div v-if="userProfile.points >= currentRedeemPoints.labs">
                <p>{{ $t('redeem_user_select_cards') }}</p>
                <div class="selection">
                  <select v-model="currentRedeemPoints.cards">
                    <option
                      v-for="i in Math.floor(
                        userProfile.points / currentRedeemPoints.labs
                      )"
                      :value="i"
                    >
                      {{ i }} <span v-if="i > 1">{{ $t('redeem_cards') }}</span
                      ><span v-else>{{ $t('redeem_card') }}</span>
                    </option>
                  </select>
                  <CommunityButton
                    type="button"
                    name="button"
                    :disabled="disableRedeemButton"
                    v-on:click="redeemPoints()"
                    >Canjear</CommunityButton
                  >
                </div>
              </div>
              <p
                class="text-center"
                v-if="
                  userProfile.points < currentRedeemPoints.labs && !redeemed
                "
              >
                No tienes suficientes LABs para canjear este premio
              </p>
              <div v-if="redeemed" class="text-center">
                <p>{{ $t('redeem_congratulations1') }}</p>
                <p>{{ $t('redeem_congratulations2') }}</p>
                <p>{{ $t('redeem_congratulations3') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="CompleteRedeemModal" v-if="!hideCompletionModal">
      <div class="CompleteRedeemModal--Content">
        <div
          class="CompleteRedeemModal--Close"
          v-on:click="hideCompletionModal = true"
        >
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.5 24.9516L17.5484 17L25.5 9.04839C25.6514 8.89697 25.6514 8.65143 25.5 8.5C25.3486 8.34858 25.103 8.34858 24.9516 8.50001L17 16.4516L9.04838 8.50001C8.89696 8.34858 8.65142 8.34858 8.5 8.5C8.34857 8.65143 8.34857 8.89697 8.49999 9.04839L16.4516 17L8.5 24.9516C8.34857 25.1031 8.34857 25.3486 8.5 25.5C8.65142 25.6514 8.89696 25.6514 9.04838 25.5L17 17.5484L24.9516 25.5C25.103 25.6514 25.3486 25.6514 25.5 25.5C25.6514 25.3486 25.6514 25.1031 25.5 24.9516Z"
              fill="#96C800"
              stroke="#96C800"
            />
          </svg>
        </div>
        <h1>{{ $t('redeem_transaction_code') }}</h1>
        <CommunityInput type="text" required v-model="redeemText" />
        <CommunityButton
          :disabled="redeemText == ''"
          v-on:click="
            !redeemUser ? completeAllRedeems() : completeRedeems(redeemUser)
          "
          >{{ $t('save') }}</CommunityButton
        >
      </div>
    </div>
  </GradientBackLayout>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapMutations } from 'vuex'
import { COMMUNITY_FETCH_MEMBERS } from '@/store/community/members.module'
import {
  COMMUNITY_UPDATE_REDEEM_INFO,
  COMMUNITY_FETCH_INFO,
} from '@/store/community/info.module'
import {
  COMMUNITY_FETCH_PENDING_REDEEMS,
  COMMUNITY_FETCH_COMPLETE_REDEEMS,
  COMMUNITY_COMPLETE_REDEEM,
  COMMUNITY_ADD_REDEEM,
  COMMUNITY_FETCH_POINTS_EXCEL,
  COMMUNITY_FETCH_PENDING_RAFFLE,
  COMMUNITY_COMPLETE_PENDING_RAFFLE,
  COMMUNITY_COMPLETE_ASK_NOTIFICATION
} from '@/store/community/redeem.module'
import { USER_FETCH_PROFILE } from '@/store/user.module'
import store from '@/store'
import CommunityButton from '@/components/CommunityButton'
import { sameDayDates } from '@/utils'
import moment from 'moment-timezone'
import GradientBackLayout from '@/layouts/GradientBackLayout'
import BoxShadow from '@/components/BoxShadow.vue'
import Separator from '@/components/Separator'
import CommunityInput from '@/components/CommunityInput.vue'
import Pagination from 'v-pagination-3'
import CommunityUser from '@/components/CommunityUser'
import FileSaver from 'file-saver'

export default {
  name: 'ActivitiesPage',

  components: {
    CommunityButton,
    GradientBackLayout,
    BoxShadow,
    Separator,
    CommunityInput,
    Pagination,
    CommunityUser,
  },

  computed: {
    ...mapGetters([
      'isClient',
      'isModerator',
      'isUser',
      'communityInfo',
      'userProfile',
      'origin',
      'theme',
      'communityMembers',
      'isCommunityMainAdmin',
      'communityHasRaffle'
    ]),
  },

  data() {
    return {
      disableRedeemButton: false,
      redeemEdition: {
        pointsRedeemDate: '',
        pointsRedeemDateTo: '',
      },
      redeemEnabled: false,
      hideModal: true,
      hideCompletionModal: true,
      redeemed: false,
      currentRedeemPoints: {},
      activeTab: 0,
      pendingRedeems: [],
      completedRedeems: [],
      pagination: 1,
      allRedeemCheck: false,
      redeemUser: false,
      redeemText: '',
      raffleList:[],
      isRedeemRaffleLoaded: false,
      isRedeemManagementLoaded: false
    }
  },

  methods: {
    getWinnersList(list){
      const array = list.join("<br>")
      return array
    },
    async saveDates() {
      let isValid = true
      if (this.$refs.dateFrom.validate()) isValid = false
      if (this.$refs.dateTo.validate()) isValid = false
      if (!isValid) return
      let data = {
        pointsRedeemDate: new Date(this.redeemEdition.pointsRedeemDate),
        pointsRedeemDateTo: new Date(this.redeemEdition.pointsRedeemDateTo),
        redeemCards: this.theme.redeemCards,
      }
      await store
        .dispatch(COMMUNITY_UPDATE_REDEEM_INFO, { data: data })
        .then((data) => {
          Swal.fire({
            text: this.$t('success_correctly_saved'),
            icon: 'success',
            customClass: { popup: 'swal-customSuccess' },
          })
        })
    },
    disableRedeem() {
      if (!moment(this.theme.pointsRedeemDate).isAfter(moment())) {
        if (
          this.theme.pointsRedeemDateTo &&
          !moment(this.theme.pointsRedeemDateTo).isAfter(moment())
        ) {
          this.redeemEnabled = false
        } else {
          this.redeemEnabled = true
        }
      } else {
        this.redeemEnabled = false
      }
    },
    openModal(c) {
      if (!this.isClient) {
        this.currentRedeemPoints = {}
        let type = c.name.toUpperCase()
        if (
          c.name.toUpperCase() != 'AMAZON' &&
          c.name.toUpperCase() != 'PAYPAL'
        ) {
          if (c.sortName != undefined && c.sortName != null) {
            type = 'CUSTOM'
            this.currentRedeemPoints.sortName = c.sortName
          } else type = 'RAFFLE'
        }
        this.currentRedeemPoints.type = type
        this.currentRedeemPoints.labs = c.labs
        this.currentRedeemPoints.prize = c.prize
        this.currentRedeemPoints.logo = c.logo
        this.currentRedeemPoints.name = c.name
        this.currentRedeemPoints.description = c.description
        this.redeemed = false
        this.hideModal = false
      }
    },
    closeModal() {
      this.hideModal = true
      this.redeemed = false
    },
    redeemPoints: async function () {
      let data = {
        points: this.currentRedeemPoints.cards * this.currentRedeemPoints.labs,
        redeemType: this.currentRedeemPoints.type,
        name: this.currentRedeemPoints.name,
        sortName:
          (this.currentRedeemPoints.sortName != null) |
          (this.currentRedeemPoints.sortName != undefined)
            ? this.currentRedeemPoints.sortName
            : undefined,
      }
      if (
        this.currentRedeemPoints.cards == undefined ||
        this.currentRedeemPoints.cards == 0
      )
        return
      this.disableRedeemButton = true
      await store.dispatch(COMMUNITY_ADD_REDEEM,{data:data})
      .then((data) => {
        this.redeemed = true
        this.currentRedeemPoints.cards = 0
        store.dispatch(USER_FETCH_PROFILE)
        this.disableRedeemButton = false
      })
    },
    async fetchExcel(){
      await store.dispatch(COMMUNITY_FETCH_POINTS_EXCEL, {}).then((data) => {
        var file = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        FileSaver.saveAs(
          file,
          'Labs ' + this.$t('community') + ' ' + this.communityId + '.xlsx'
        )
      })
    },
    fetchMembers: async function(){
      await store.dispatch(COMMUNITY_FETCH_MEMBERS,{
        order:"recent",
      });
    },
    fetchPendingRedeems: async function (page) {
      await store
        .dispatch(COMMUNITY_FETCH_PENDING_REDEEMS, { page: page - 1 })
        .then((data) => {
          for (let o of data.objects) {
            o.member = this.findMemberById(o.ownerId)
            o.selected = false
          }
          this.pendingRedeems = data
          this.isRedeemManagementLoaded = true;
          if (this.activeTab == 1)
            $('.RedeemPageV2').animate({ scrollTop: 0 }, 800);
        })
    },
    fetchCompleteRedeems: async function (page) {
      await store
        .dispatch(COMMUNITY_FETCH_COMPLETE_REDEEMS, { page: page - 1 })
        .then((data) => {
          for (let o of data.objects) {
            o.member = this.findMemberById(o.ownerId)
          }
          this.completedRedeems = data
        })
    },
    findMemberById(id) {
      for (let m of this.communityMembers) {
        if (m.identifier == id) return m
      }
    },
    selectAllRedeem() {
      if (this.allRedeemCheck) {
        for (let r of this.pendingRedeems.objects) {
          r.selected = false
        }
      } else {
        for (let r of this.pendingRedeems.objects) {
          r.selected = true
        }
      }
      this.allRedeemCheck = !this.allRedeemCheck
    },
    completeAllRedeems: async function () {
      let vm = this
      Swal.fire({
        title: vm.$t('msg_confirmation'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_complete'),
        showCancelButton: true,
        customClass: {
          popup: 'swal-customWarning',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            elPointsTransactionInfo: {},
          }
          for (let p of vm.pendingRedeems.objects) {
            if (p.selected)
              data.elPointsTransactionInfo[p.identifier] = this.redeemText
          }
          store
            .dispatch(COMMUNITY_COMPLETE_REDEEM, { data: data })
            .then((data) => {
              this.redeemUser = false
              this.hideCompletionModal = true
              this.redeemText = ''
              Swal.fire({
                text: vm.$t('redeem_success'),
                icon: 'success',
                customClass: { popup: 'swal-customSuccess' },
              })
              vm.fetchPendingRedeems(vm.pagination)
            })
        }
      })
    },
    completeRedeems: async function (redeem) {
      let vm = this
      Swal.fire({
        title: vm.$t('msg_confirmation'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_complete'),
        showCancelButton: true,
        customClass: {
          popup: 'swal-customWarning',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          let data = { elPointsTransactionInfo: {} }
          data.elPointsTransactionInfo[redeem.identifier] = this.redeemText
          store
            .dispatch(COMMUNITY_COMPLETE_REDEEM, { data: data })
            .then((data) => {
              this.redeemUser = false
              this.hideCompletionModal = true
              this.redeemText = ''
              Swal.fire({
                text: vm.$t('redeem_success'),
                icon: 'success',
                customClass: { popup: 'swal-customSuccess' },
              })
              vm.fetchPendingRedeems(vm.pagination)
            })
        }
      })
    },
    async redeemRaffle(raffle){
      let vm = this
      Swal.fire({
        title: vm.$t('msg_confirmation'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_complete'),
        showCancelButton: true,
        customClass: {
          popup: 'swal-customWarning',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(COMMUNITY_COMPLETE_PENDING_RAFFLE, { activityId: raffle.activityId})
            .then((data) => {
              Swal.fire({
                text: vm.$t('redeem_raffle_success'),
                icon: 'success',
                customClass: { popup: 'swal-customSuccess' },
              })
              vm.fetchRaffle()
            })
        }
      })
    },
    async notificateRaffle(raffle){
      let vm = this
      Swal.fire({
        title: vm.$t('msg_confirmation'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_complete'),
        showCancelButton: true,
        customClass: {
          popup: 'swal-customWarning',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(COMMUNITY_COMPLETE_ASK_NOTIFICATION, { activityId: raffle.activityId})
            .then((data) => {
              Swal.fire({
                text: vm.$t('redeem_raffle_success_notificated'),
                icon: 'success',
                customClass: { popup: 'swal-customSuccess' },
              })
              vm.fetchRaffle()
            })
        }
      })
    },
    async fetchRaffle(){
      await store.dispatch(COMMUNITY_FETCH_PENDING_RAFFLE)
      .then((data) => {
        this.raffleList = data
        this.isRedeemRaffleLoaded = true
      })
    }
  },
  async mounted() {
    this.redeemEdition.pointsRedeemDate =
      this.theme.pointsRedeemDate != undefined
        ? this.theme.pointsRedeemDate
        : ''
    this.redeemEdition.pointsRedeemDateTo =
      this.theme.pointsRedeemDateTo != undefined
        ? this.theme.pointsRedeemDateTo
        : ''
    if (this.isClient) {
      await this.fetchMembers()
      this.fetchPendingRedeems(1)
      this.fetchCompleteRedeems(1)
      this.fetchRaffle()
    }
    this.disableRedeem()
  },
}
</script>
<style scoped lang="scss">
.RedeemPageV2 {
  button {
    background: var(--accent-color) !important;
  }
  &__Tabs {
    position: absolute;
    top: -32px;
    right: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    &--tab {
      background: #f6f6f6;
      cursor: pointer;
      display: flex;
      margin-right: 5px;
      padding: 7px 16px;
      font-size: 15px;
      letter-spacing: -0.01em;
      &:last-of-type {
        margin-right: 10px;
      }
      &.active {
        background: white;
      }
    }
  }
  .spinner__raffle {
    display: flex;
    margin-top: 30%;
    margin-left: 350%;
  }
  .spinner__management {
    display: flex;
    margin-top: 20%;
    margin-left: 250%;
  }
  &--Box {
    display: flex;
    flex-direction: column;
    padding: 30px 50px 50px 50px;
    .TesterNotExists {
      background: #F5F5F5;
      padding: 9px;
      position: relative;
      min-width: 220px;
      p {
        text-align: center;
      }
    }
  }
  h1 {
    font-size: 25px;
    letter-spacing: -0.01em;
    color: #3e3e3e;
    margin-bottom: 20px;
  }
  &--Date {
    width: 100%;
    &:first-of-type {
      margin-right: 15px;
    }
  }
  &--Dates {
    display: flex;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      
    }
    p {
      font-size: 17px;
      letter-spacing: -0.01em;
      color: #3e3e3e;
    }
  }
  &--InfoText {
    font-size: 13px;
    letter-spacing: 0.01em;
    color: #3e3e3e;
    margin-top: 18px;
  }
  &--Save {
    margin: 10px 0 10px auto;
    background: var(--accent-color) !important;
  }
  &--Cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &--Card {
    width: calc(50% - 10px);
    background: #ffffff;
    border: 1px solid #e1e1e1;
    filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.08));
    padding: 20px;
    margin-bottom: 20px;
    p {
      color: #747474;
      font-size: 18px;
      margin-top: 10px;
    }
  }
  &--CardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      
    }
    div {
      width: 120px;
      height: 60px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    button {
      font-size: 15px;
      background: var(--accent-color) !important;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    .Tablecheckbox {
      input {
        display: none;
      }

      label:before {
        content: '';
        background: transparent;
        border: 3px solid #e0e0e0;
        display: inline-block;
        height: 29px;
        width: 29px;
        margin-right: 20px;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        font-size: 15px;
        font-family: 'Times New Roman';
        color: white;
        transition: 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      input:checked + label:before {
        content: '\2714';
        background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 20px;
      }
    }
    th {
      font-size: 17px;
      letter-spacing: -0.01em;
      color: #3e3e3e;
      text-align: left;
      padding: 10px;
      font-weight: 400;
      &:first-of-type {
        padding-left: 0;
      }
    }
    td {
      padding-top: 10px;
    }
    button {
      font-size: 15px;
      background: var(--accent-color) !important;
    }
    tr:first-of-type,
    tr:last-of-type {
      border-bottom: 1px solid #e0e0e0;
    }
    .center {
      text-align: -webkit-center;
      p {
        font-weight: bold;
        font-size: 20px;
        letter-spacing: 0.01em;
        color: #3e3e3e;
      }
    }
  }
}
.CompleteRedeemModal {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  &--Content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 400px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    padding: 10px;
  }
  &--Close {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.08));
    position: absolute;
    right: 20px;
    top: -15px;
    cursor: pointer;
  }
  h1 {
    font-size: 20px;
  }
  button {
    margin-top: 20px;
  }
}
.Modal {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  &__content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 60%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }
  &__header {
    padding: 6px 16px;
    background-color: var(--primary-color);
    color: white;
    h2 {
      font-weight: 500;
      font-size: 18px;
    }
  }

  &__body {
    padding: 16px;
    font-size: 13px;
    color: #8a8a8a;
    &__content {
      display: flex;
    }
    p {
      margin: 10px 0;
    }
    .imgs {
      padding: 20px;
      img {
        border: 1px solid lightgray;
        width: 150px;
        height: 120px;
        margin-right: 20px;
        padding: 10px;
      }
    }
    .selection {
      display: flex;
      select {
        background: #ffffff;
        border: 1px solid #e1e1e1;
        filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.08));
        font-size: 15px;
        letter-spacing: 0.01em;
        color: #3e3e3e;
        outline: none;
        padding: 8px;
      }
      button {
        margin-left: 20px;
        font-size: 18px;
        background: var(--accent-color) !important;
      }
    }
    .text-center {
      font-size: 15px;
      text-align: justify;
      padding: 10px 0 0 0;
      color: var(--primary-color);
      font-weight: bold;
    }
  }
  .close {
    color: white;
    float: right;
    font-size: 20px;
    font-weight: bold;
    &:hover {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }
  @-webkit-keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  @media screen and (max-width: 768px) {
    .Modal__content {
      width: 100%;
    }
  }
  @media screen and (max-width: 495px) {
    .Modal__body__content {
      flex-direction: column;
      align-items: center;
    }
  }
}
@media screen and (max-width: 768px) {
  .RedeemPageV2--Card {
    width: 100%;
  }
}
</style>
<style lang="scss">
.RedeemPageV2 {
  .mx-input-append {
    background: transparent;
    margin: 5px;
  }
}
</style>